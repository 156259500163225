body {
  background-color: #cecece !important;
}

.sits-card {
  margin: 1px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 1px;
  border: none;
  border-color: white;
  border-radius: unset;
  background-color: white !important;
}

.sits-kachel {
  border-radius: unset;
}

.sits-container {
  display: flex !important;
  justify-content: center;
  background-color: #cecece;
}

.sits-card .dx-fieldset-header {
  background-color: white;
  color: #fff;
  padding: 0px;
  border-radius: unset;
}

.sits-card-small .dx-fieldset-header {
  background-color: #cecece;
  color: #fff;
  padding: 0px;
  border-radius: unset;
}

.sits-card-large {
  border-radius: unset;
}

.sits-card-large .dx-fieldset-header {
  background-color: #cecece;
  color: #fff;
  padding: 0px;
  border-radius: unset;
}

.sits-card-small {
  border-radius: unset;
}